// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./controllers"
import 'swiper/css/bundle'
import 'lightgallery/css/lightgallery.css'

import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: [
      'IBM+Plex+Sans:400,500,600,700',
      'Playfair+Display:400,500,600,700,800'
    ],
  },
});

// Mobile header menu
const menuOpenBtn = document.querySelector("#menu-mobile-open");
const menuCloseBtn = document.querySelector("#menu-mobile-close");
const mobileMenu = document.querySelector("#mobile-menu");
menuOpenBtn.addEventListener('click', () => {
  mobileMenu.classList.toggle('hidden');
})
menuCloseBtn.addEventListener('click', () => {
  mobileMenu.classList.toggle('hidden');
})

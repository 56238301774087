import Lightbox from 'stimulus-lightbox'

export default class extends Lightbox {
  connect() {
    super.connect()
    this.lightGallery
    this.defaultOptions
  }

  get defaultOptions() {
    return {
      counter: false,
      download: false
    }
  }
}
import { Controller } from '@hotwired/stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ["modal", "modalOpenBtn", "modalCloseBtn", "overlay", "imgboxMale", "imgboxFemale", "widthInput", "heightInput", "alertMessage", "totalRecap", "alertDimension", "alertQuantity"];
  productPrice = 0;

  connect() {
    this.getProductPrice()
    this.modalOpenBtnTarget.addEventListener("click", () => this.modalToggle());
    this.modalCloseBtnTarget.addEventListener("click", () => this.modalToggle());
    document.querySelector("#cart_item_genre_maschile").addEventListener("click", () => this.imgboxMaleToggle());
    document.querySelector("#cart_item_genre_femminile").addEventListener("click", () => this.imgboxFemaleToggle());
    this.widthInputTarget.addEventListener('input', this.debounce(this.handleInput.bind(this), 500))
    this.heightInputTarget.addEventListener('input', this.debounce(this.handleInput.bind(this), 500))
  }

  disconnect() {
    this.widthInputTarget.removeEventListener('input', this.handleInput.bind(this))
    this.heightInputTarget.removeEventListener('input', this.handleInput.bind(this))
  }

  debounce(func, delay) {
    return (...args) => {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => func.apply(this, args), delay)
    }
  }

  async getProductPrice() {
    try {
      const res = await axios.get(`/products/${this.totalRecapTarget.dataset.productid}/json`)
      this.productPrice = res.data.price_cents;
    } catch (error) {
      console.error(error);
    }
  }

  modalToggle() {
    this.overlayTarget.classList.toggle("hidden");
    this.modalTarget.classList.toggle("hidden");
  }

  imgboxMaleToggle() {
    if (this.imgboxMaleTarget.classList.contains("hidden")) {
      this.imgboxMaleTarget.classList.remove("hidden");
      this.imgboxFemaleTarget.classList.add("hidden");
    }
  }

  imgboxFemaleToggle() {
    if (this.imgboxFemaleTarget.classList.contains("hidden")) {
      this.imgboxFemaleTarget.classList.remove("hidden");
      this.imgboxMaleTarget.classList.add("hidden");
    }
  }

  handleInput() {
    const width = this.widthInputTarget.value
    const height = this.heightInputTarget.value
  
    if (width && !this.validateInput(width)) {
      this.alertDimensionTarget.innerHTML = `Inserisci un numero maggiore o uguale a 100 per la larghezza.`;
      this.alertDimensionTarget.classList.add('opacity-100');
      setTimeout(() => {
        this.alertDimensionTarget.classList.remove('opacity-100');
      }, 3500);

      this.widthInputTarget.value = ''
      this.totalRecapTarget.innerHTML = `&euro;${this.productPrice / 100}`
      return
    }
  
    if (height && !this.validateInput(height)) {
      this.alertDimensionTarget.innerHTML = `Inserisci un numero maggiore o uguale a 100 per altezza.`;
      this.alertDimensionTarget.classList.add('opacity-100');
      setTimeout(() => {
        this.alertDimensionTarget.classList.remove('opacity-100');
      }, 3500);

      this.heightInputTarget.value = ''
      this.totalRecapTarget.innerHTML = `&euro;${this.productPrice / 100}`
      return
    }
    
    // If one of the inputs is deleted by the user, reset totalRecapTarget
    if (!width || !height || width < 100 || height < 100 || width === '' || height === '') {
      this.alertDimensionTarget.innerHTML = `Inserisci un numero maggiore o uguale a 100 per larghezza ed altezza.`;
      this.alertDimensionTarget.classList.add('opacity-100');
      setTimeout(() => {
        this.alertDimensionTarget.classList.remove('opacity-100');
      }, 3500);

      this.totalRecapTarget.innerHTML = `&euro;${this.productPrice / 100}`
      return
    }
  
    // If validation passes and both inputs are not empty, execute calculations
    if (width && height) {
      this.updateTotal(width, height)
    }
  }

  validateInput(input) {
    const decimalRegex = /^\d*\.?\d+$/
    const number = parseFloat(input)
    return decimalRegex.test(input) && !isNaN(number) && number >= 100
  }

  updateTotal(width, height) {
    this.totalRecapTarget.innerHTML = `&euro;${this.calculateTotal(width, height)}`
  }

  calculateTotal(width, height) {
    return ((Math.ceil((width / 100) * (height / 100)) * this.productPrice) / 100).toFixed(2)
  }

}
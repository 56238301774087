import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["incrementBtn", "decrementBtn", "inputQuantity"];

  connect() {
    this.incrementBtnTarget.addEventListener("click", () => this.incrementQuantity());
    this.decrementBtnTarget.addEventListener("click", () => this.decrementQuantity());
  }

  incrementQuantity() {
    this.inputQuantityTarget.value = parseInt(this.inputQuantityTarget.value, 10) + 3;
  }

  decrementQuantity() {
    if (parseInt(this.inputQuantityTarget.value, 10) >= 3) {
      this.inputQuantityTarget.value = parseInt(this.inputQuantityTarget.value, 10) - 3;
    } else {
      this.inputQuantityTarget.value = 0;
    }
  }
}
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["colorSelectBtn", "colorSelectCloseBtn", "modal", "overlay", "inputQuantity", "confirmBtn", "alertMessage", "productRecap", "missingQuantity", "decrementBtn", "incrementBtn"];

  connect() {
    this.colorSelectBtnTarget.addEventListener("click", () => this.modalToggle());
    this.colorSelectCloseBtnTarget.addEventListener("click", () => this.modalToggle());
    this.confirmBtnTarget.addEventListener("click", function(e) { this.submitCanvas(e) }.bind(this));
    this.incrementBtnTargets.forEach(btn => {
      btn.addEventListener("click", () => {
        setTimeout(() => {
          this.updateMissingQuantity();
        }, 10);
      });
    });
    this.decrementBtnTargets.forEach(btn => {
      btn.addEventListener("click", () => {
        setTimeout(() => {
          this.updateMissingQuantity();
        }, 10);
      });
    });
    this.inputQuantityTargets.forEach(input => {
      input.addEventListener("change", () => {
        setTimeout(() => {
          this.updateMissingQuantity();
        }, 10);
      });
    });
  }

  modalToggle() {
    this.overlayTarget.classList.toggle("hidden");
    this.modalTarget.classList.toggle("hidden");
  }

  areAllMultiplesOfThree() {
    let allMultiples = true;
    this.inputQuantityTargets.forEach(input => {
      if (input.value > 0 && input.value % 3 !== 0) {
        allMultiples = false;
      }
    });
    return allMultiples;
  }

  submitCanvas(e) {
    const inputs = Array.from(this.inputQuantityTargets);
    const relationQuantity = parseInt(this.confirmBtnTarget.dataset.relationQuantity, 10);
    const inputValuesSum = inputs
      .map(input => parseInt(input.value, 10))
      .reduce((a, b) => a + b, 0);

    if (inputValuesSum !== relationQuantity) {
      e.preventDefault();
      this.alertMessageTarget.innerHTML = `La somma delle quantità inserite deve essere uguale a ${relationQuantity}.`;
      this.alertMessageTarget.classList.add('opacity-100');

      setTimeout(() => {
        this.alertMessageTarget.classList.remove('opacity-100');
      }, 3500);

      this.productRecapTarget.innerHTML = "";
      this.colorSelectBtnTarget.innerHTML = "Scegli colori";
    } else if (this.areAllMultiplesOfThree() == false) {
      e.preventDefault();
      this.alertMessageTarget.innerHTML = "I quantitativi devono essere multipli di 3";
      this.alertMessageTarget.classList.add('opacity-100');

      setTimeout(() => {
        this.alertMessageTarget.classList.remove('opacity-100');
      }, 3500);

      this.productRecapTarget.innerHTML = "";
      this.colorSelectBtnTarget.innerHTML = "Scegli colori";
    } else {
      const productRecap = inputs
        .filter(input => parseInt(input.value, 10) > 0)
        .map(input => input.dataset.vName + ': ' + input.value + 'pz')
        .join(' - ');

      this.productRecapTarget.innerHTML = productRecap;
      this.colorSelectBtnTarget.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16"><g transform="translate(0, 0)"><path d="M11.707.293a1,1,0,0,0-1.414,0l-9,9A1,1,0,0,0,1.02,9.8l-1,5A1,1,0,0,0,1.2,15.98l5-1a1,1,0,0,0,.511-.273l9-9a1,1,0,0,0,0-1.414Zm-6.2,12.786-3.233.647.647-3.233L8,5.414,10.586,8ZM12,6.586,9.414,4,11,2.414,13.586,5Z" fill="currentColor"></path></g></svg>';
      this.modalTarget.classList.toggle('hidden');
      this.overlayTarget.classList.toggle('hidden');
    }
  }

  updateMissingQuantity() {
    const inputs = Array.from(this.inputQuantityTargets);
    const relationQuantity = parseInt(this.confirmBtnTarget.dataset.relationQuantity, 10);
    const inputValuesSum = inputs
      .map(input => parseInt(input.value, 10))
      .reduce((a, b) => a + b, 0);

    const missingQuantity = relationQuantity - inputValuesSum;

    if (missingQuantity > 0 && missingQuantity < relationQuantity) {
      this.missingQuantityTarget.innerHTML = `Devi inserire altri ${missingQuantity} pz`;
    } else if (missingQuantity === 0) {
      this.missingQuantityTarget.innerHTML = `Puoi procedere con la conferma`;
    } else if (missingQuantity < 0) {
      this.missingQuantityTarget.innerHTML = `Hai inserito ${Math.abs(missingQuantity)} pz in più`;
    } else {
      this.missingQuantityTarget.innerHTML = `Inserisci ${relationQuantity} pz`;
    }
  }
}
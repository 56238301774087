import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {
  static targets = ["button"];

  connect() {
    super.connect()
    this.buttonTarget.addEventListener("click", () => this.changeLabel());
  }

  changeLabel() {
    const content = (this.buttonTarget.innerHTML === 'Vedi prodotti') ? 'Nascondi prodotti' : 'Vedi prodotti';
    this.buttonTarget.innerHTML = content;
  }
}
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button", "inputField", "btnConfirm"];

  connect() {
    this.inputFieldTarget.addEventListener("click", () => this.handleClick());
    this.btnConfirmTarget.addEventListener("click", () => this.handleClick());
  }

  handleClick() {
    this.buttonTargets.forEach(button => button.classList.toggle("!hidden"));
    this.btnConfirmTarget.classList.toggle("!hidden");
  }
}